import React, { useState } from 'react';
import './Home.css';
import logo from '../../../../src/Assets/New_Logo.png';
import _0x5o6p from '../../../Assets/nameAlgov2.js';

const Home = () => {
  const [inputValue, setInputValue] = useState('');
  const [compoundName, setCompoundName] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessage('');
    setCompoundName('');

    // Validate input
    if (!inputValue) {
      setErrorMessage('Please enter a chemical formula.');
      setLoading(false);
      return;
    }

    // Enforce uppercase input
    if (inputValue !== inputValue.toUpperCase()) {
      setErrorMessage(
        'Please enter the formula in uppercase. The app is case-sensitive.'
      );
      setLoading(false);
      return;
    }

    try {
      // Use parseHydrocarbon to get the compound name
      const result = _0x5o6p(inputValue);
      if (
        result === 'Invalid character in formula!' ||
        result === 'Invalid structure!'
      ) {
        setErrorMessage(result);
      } else {
        setCompoundName(
          result.replace(/[a-zA-Z](?!-)/, (match) => match.toUpperCase())
        );
      }
    } catch (error) {
      setErrorMessage(
        'Could not determine the name. Please check the formula or try again.'
      );
      console.error('Error processing formula:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setErrorMessage('');
    setCompoundName('');
  };

  const handleBotClick = () => {
    setShowPopup(true);
  };

  const handlePopupOption = (option) => {
    const url =
      option === 'telegram'
        ? 'https://t.me/Chemlabb_bot'
        : 'https://github.com/Kaeytee/chemlab-bot.git';

    /*************  ✨ Codeium Command ⭐  *************/
    /**
     * Handles the user's choice from the popup options, opening a corresponding URL
     * and closing the popup.
     *
     * @param {string} option - The selected option, either "telegram" or "github".
     *                          "telegram" opens the Telegram bot link and "github"
     *                          opens the GitHub repository link.
     */
    /******  556987af-04e4-4010-94bf-e995ba9eca7a  *******/ window.open(
      url,
      '_blank'
    );
    setShowPopup(false);
  };

  return (
    <div className="home">
      <div className="home-content">
        <h2>This app is capable of accurately naming straight-chain alkanes, alkenes, alkynes, alkanoic acids, and alkanols. The functionality for naming compounds with substituents is currently under development.</h2>
        <div className="card">
          <img src={logo} alt="Logo of ChemLab" />
          <h1>Chem Lab Inc</h1>
          <p>Enter a molecular formula to get its IUPAC name.</p>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <label className={`placeholder ${inputValue ? 'active' : ''}`}>
                Enter a chemical formula (e.g., CH3OH)
              </label>
              <input
                className="input"
                type="text"
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>
            <button className="sbt" type="submit" disabled={loading}>
              {loading ? 'Loading...' : 'Submit'}
            </button>
            <span className="telegram-link" onClick={handleBotClick}>
              @chemlab-bot
            </span>
          </form>
          {compoundName && (
            <p className="compound-result">IUPAC Name: {compoundName}</p>
          )}
          {errorMessage && <p className="error">{errorMessage}</p>}
          {showPopup && (
            <div className="popup-overlay" onClick={() => setShowPopup(false)}>
              <div className="popup" onClick={(e) => e.stopPropagation()}>
                <h3>Have a feel of our Telegram BOT</h3>
                <div className="popup-buttons">
                  <button
                    className="popup-btn call-btn"
                    onClick={() => handlePopupOption('telegram')}
                  >
                    <i className="fab fa-telegram-plane"></i> ChemLab-Bot
                  </button>
                  <button
                    className="popup-btn github-btn"
                    onClick={() => handlePopupOption('github')}
                  >
                    <i className="fab fa-github"></i> GitHub
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
